import {
	SiteBlocks,
	SiteBlogCategories,
	SiteEcommerceShoppingCart,
	SiteElements,
	SiteForms,
	SiteMeta,
	SiteNavItem,
	SitePages,
	SiteStyles,
} from '@hostinger/builder-schema-validator';

export interface ElasticSearchVariant {
	amount: number;
	sale_amount: number | null;
	currency_decimal_digits: number;
	currency_template: string;
	manage_inventory: boolean;
	inventory_quantity: number;
	currency_code: string;
	id: string;
}
export interface ElasticSearchResult {
	id: string;
	title: string;
	thumbnail: string;
	site_product_selection: string | null;
	variants: Array<ElasticSearchVariant>;
}

export interface SiteElementSearchItem {
	id: string;
	thumbnail: string;
	title: string;
	href: string;
	price: string;
	oldPrice: string | null;
	isInStock: boolean;
}

export interface EcommerceProductOptionValue {
	id: string;
	option_id: string;
	value: string;
	variant_id: string;
}
export interface EcommerceProductOption {
	id: string;
	title: string;
	value: EcommerceProductOptionValue;
	values: Array<EcommerceProductOptionValue>;
}
export interface EcommerceBookingEvent {
	id: string;
	buffer_time_after: number | null;
	buffer_time_before: number | null;
	length: number;
	length_unit: string;
	location: string;
	minimum_booking_notice: number | null;
	minimum_booking_notice_unit: string;
	schedule_id: string;
}
export interface EcommerceCurrency {
	code: string;
	decimal_digits: number;
	min_amount: number;
	name: string;
	name_plural: string;
	rounding: number;
	symbol: string;
	symbol_native: string;
	template: string;
}
export interface EcommerceVariantPrice {
	amount: number;
	currency_code: string;
	sale_amount: number | null;
	currency: EcommerceCurrency
}
export interface EcommerceProductVariant {
	id: string;
	image_url?: string;
	manage_inventory: boolean;
	options: Array<EcommerceProductOptionValue> | [];
	sku?: string;
	booking_event?: EcommerceBookingEvent,
	prices: Array<EcommerceVariantPrice> | [];
	title?: string;
}

export interface EcommerceProductVariantQuantity {
	id: string;
	inventory_quantity: number;
}
export interface EcommerceProductAdditionalInfo {
	id: string;
	title: string;
	description: string;
	order: number;
}

export interface EcommerceProductCustomField {
	id: string;
	title: string;
	is_required: boolean;
	value?: string;
}
export interface EcommerceProductImage {
	order: number;
	url: string;
	type: string;
}

export interface EcommerceProductSeoSettings {
	title?: string;
	noindex?: boolean;
	keywords?: string[];
	description?: string;
	focusKeyword?: string;
	ogImageOrigin?: string;
	ogImagePath?: string;
	ogImageAlt?: string;
	slug?: string;
}

export type EcommerceUpdateProductsSeoPayload = {
	id: string;
	seo_settings: EcommerceProductSeoSettings
}

export type SiteEcommerceSeoChange = {
	id: string;
	seo_settings: EcommerceProductSeoSettings;
}

export type SiteEcommerceSeoChangesSchema = SiteEcommerceSeoChange[];
export interface EcommerceCollection {
	id: string;
	title: string;
	created_at: string;
	updated_at: string;
	delete_at: string | null;
	image_url: string | null;
	metadata: Record<string, any> | null;
	store_id: string;
}
export interface EcommerceProductCollection {
	collection_id: string;
	order: number;
	product_id: string;
}

export enum EcommerceProductType {
	PHYSICAL = 'physical',
	DIGITAL = 'digital',
	DONATION = 'donation',
	BOOKING = 'booking',
	SERVICE = 'service',
}
export interface EcommerceProduct {
	id: string | number;
	description: string;
	images?: EcommerceProductImage[] | [];
	media?: EcommerceProductImage[] | []
	options: EcommerceProductOption[] | [];
	product_collections: Array<EcommerceProductCollection>;
	ribbon_text: string | null;
	site_product_selection: string | null;
	subtitle: string | null;
	thumbnail: string | null;
	title: string;
	type: {
		value: EcommerceProductType;
	};
	variants: EcommerceProductVariant[];
	purchasable?: boolean;
	additional_info: EcommerceProductAdditionalInfo[];
	seo_settings: EcommerceProductSeoSettings;
	custom_fields?: EcommerceProductCustomField[];
}

export interface EcommerceQuantifiedProductData extends EcommerceProduct {
	variants: Array<EcommerceProductVariant & {
		booking_event: {
			time_slot: string,
			date: Date | string,
			length: number,
			length_unit: string
		}
	}>
}
export interface EcommerceQuantifiedItem {
	product: EcommerceQuantifiedProductData,
	quantity: number
}

export interface EcommerceRegion {
	id: string;
	name: string;
  currency_code: string;
	created_at: string;
	updated_at: string;
	metadata: Record<string, any> | null;
	countries: object[]
}

export interface EcommerceShippingRequirement {
  id: string;
	shipping_option_id: string;
	type: string;
	amount: number;
	deleted_at: Date;
}

export interface EcommerceShippingOption {
  id: string;
	name: string;
	region_id: string;
	region: EcommerceRegion;
	provider_id: string;
	provider?: object;
	price_type: string;
	amount: number | null;
	is_return: boolean;
	admin_only: boolean;
	requirements: EcommerceShippingRequirement[];
	data: Record<string, unknown> | null;
	metadata: Record<string, unknown> | null;
}
export type EcommerceRegionShippingOptions = { name: string, shippingOptions: EcommerceShippingOption[]}[];

export interface EcommerceProductSeoSettingsData {
	id: string;
	title: string;
	lowest_amount: number;
	highest_amount: number;
	is_hidden?: boolean;
	currency: {
		code: string;
		template: string;
		decimal_digits: number;
	};
	seo_settings: EcommerceProductSeoSettings;
}

export interface EcommerceProductPage {
	productId: string;
	slug: string;
}

export type UpsertRegionCountry = {
	country_code: string
	provinces: string[]
}

export interface SiteLanguageSwitcherLanguage {
	country: string;
	flagPath: string;
	href: string;
	isHidden: boolean;
	locale: string;
	name: string;
}

export interface SiteBlockHeaderItem {
	id: string;
	name: string;
	url: string;
	isCurrent: boolean;
	href: string;
	target: string;
	rel: string;
	hasDropdown: boolean;
	subItems: SiteBlockHeaderItem[];
}

export interface GridSocialIconLink {
	link: string;
	icon: string;
	svg: string;
}

export enum SortingOptions {
	DEFAULT = 'default',
	PRICE_LTH = 'priceLTH',
	PRICE_HTL = 'priceHTL',
	MOST_RECENT = 'mostRecent',
}

export interface EcommerceProductSortingOption {
	id: SortingOptions;
	isChecked: boolean;
	value: string;
	label: string;
}

export interface EcommerceProductSorting {
	enabled: boolean;
	textColor?: string;
	sortingOptions?: EcommerceProductSortingOption[];
}

export enum ImageRatioOption {
	CONTAIN = 'contain',
	COVER = 'cover',
	LANDSCAPE = 'landscape',
	PORTRAIT = 'portrait',
}

export enum ImageHoverEffect {
	NO_EFFECT = 'no_effect',
	ZOOM = 'zoom',
	SWAP_IMAGE = 'swap_image'
}

export enum EcommerceProductListScrollBehaviour {
	PAGINATION = 'pagination',
	SCROLL = 'scroll',
}

export const ChatbotRoles = {
	user: 'user',
	assistant: 'assistant',
	system: 'system',
} as const;

type ChatbotRole = (typeof ChatbotRoles)[keyof typeof ChatbotRoles];

export interface ChatbotMessageFunction {
  arguments?: Record<string, string>;
  name: string;
}
export interface ChatbotConversationMessage {
  role: ChatbotRole;
  content?: string;
  functions?: ChatbotMessageFunction[];
}

export interface ChatbotTextsMain {
  button?: string;
  betaBadge?: string;
  title?: string;
  questionInputPlaceholder?: string;
  disclaimer?: string;
  tooltipReset?: string;
  tooltipClose?: string;
}

export interface ChatbotTextsModalRestart {
  title: string;
  description: string;
  cancelButton: string;
  confirmButton: string;
}

export interface ChatbotTexts {
  main: ChatbotTextsMain;
  modalRestart?: ChatbotTextsModalRestart;
  functions?: Record<string, string>;
}

export interface ChatbotConversationHistory {
  conversationId: string;
  history: ChatbotConversationMessage[];
}

export interface ChatbotMessageResponse {
  conversation_id: string;
  message: string;
}

export interface ChatbotAvailableResponse {
	areLimitsReached: boolean
}

export interface SitePageData {
	pages: SitePages,
	blocks: SiteBlocks,
	elements: SiteElements,
	nav: SiteNavItem[],
	currentLocale: string,
	homePageId: string,
	isNavHidden: boolean,
	cookieBannerAcceptText?: string,
	cookieBannerDisclaimer?: string,
	cookieBannerDeclineText?: string,
	blogReadingTimeText?: string,
	metaTitle?: string,
	meta: SiteMeta,
	forms: SiteForms,
	styles: SiteStyles,
	domain?: string,
	siteId?: string,
	ecommerceShoppingCart?: SiteEcommerceShoppingCart,
	blogCategories: SiteBlogCategories,
	languageSwitcherLanguages: SiteLanguageSwitcherLanguage[],
	currentPageId: string,
	productId?: string,
	languageKeys: string[],
	isDynamicProductPageEnabled?: boolean,
}
